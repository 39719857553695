import styled from 'styled-components';
import {StyledButtonCardProps} from './types';
import {pxToRem} from '@utils';
import {IconButton} from '@molecules';
import {Card} from '@atoms';
import {Subtitle} from '@quarks';

export const StyledTitle = styled.p`
  font-weight: 600;
`;

export const StyledTime = styled(Subtitle)<{
  $isCanceled: boolean;
}>`
  text-decoration: ${({$isCanceled}) => $isCanceled && 'line-through'};
  text-decoration-thickness: ${({$isCanceled}) => $isCanceled && '2px'};
`;

export const StyledButtonCard = styled(Card).attrs<StyledButtonCardProps>(({$isAccepted, $isCanceled, ...attrs}) => ({
  ...attrs,
  as: 'button',
  card: $isAccepted && !$isCanceled ? 'beige200' : 'transparent',
}))<StyledButtonCardProps>`
  flex: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  min-height: ${pxToRem(180)};
  padding: 24px;
  border: ${({$isAccepted, $isCanceled, theme}) =>
    $isAccepted && !$isCanceled
      ? '2px solid transparent'
      : $isCanceled
      ? `2px solid ${theme.disabled.color}`
      : `2px dashed ${theme.disabled.color}`};
  transition: background var(--speed-fast), border var(--speed-fast);
  color: ${({$isCanceled, theme}) => $isCanceled && theme.disabled.color};
  overflow: hidden;

  @media (hover) {
    &:hover {
      background: ${({$isAccepted, $isCanceled, theme}) =>
        $isAccepted && !$isCanceled ? theme.card.beige200.hover.background : theme.card.transparent.hover.background};
    }
  }

  &:focus-visible {
    outline: none;
    border: 2px solid ${({theme}) => theme.brand};
  }

  ${StyledTitle} {
    text-decoration: ${({$isCanceled}) => $isCanceled && 'line-through'};
    text-decoration-thickness: ${({$isCanceled}) => $isCanceled && '2px'};
  }

  ${StyledTime} {
    color: ${({$isCanceled}) => $isCanceled && 'inherit'};
  }
`;

export const StyledIconButton = styled(IconButton)`
  position: absolute;
  bottom: 26px;
  right: 26px;
`;

export const StyledLocationWrapper = styled.div<{
  $isAccepted: boolean;
}>`
  align-items: flex-end;
  border-radius: 6px;
  background: ${({$isAccepted, theme}) =>
    $isAccepted ? theme.card.focusingBrown.background : theme.card.opaque.background};
  display: flex;
  padding: 6px 8px;
  column-gap: 4px;
`;

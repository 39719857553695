import styled from 'styled-components';

import {BREAKPOINTS} from '@constants';
import {PageHeader} from '@molecules';
import {FlexCol, FlexRow} from '@quarks';
import {NavLink} from 'react-router-dom';
import {StyledNavLinkProps} from './types';
import {truncateString} from '@utils';

export const HaNPageHeader = styled(PageHeader)`
  display: none;

  ${BREAKPOINTS.small`
    display: grid;
  `}
`;

export const Title = styled(FlexCol)`
  background: ${({theme}) => theme.background.white};
  padding: 0px 16px;
  justify-content: center;
  height: 60px;
  border-right: 1px solid ${({theme}) => theme.divider.grey};
  border-bottom: 1px solid ${({theme}) => theme.divider.grey};

  ${BREAKPOINTS.small`
    border-right: none;
  `}

  & > h1 {
    ${truncateString}
  }
`;

export const StyledTabs = styled(FlexRow)`
  background: ${({theme}) => theme.background.white};
  height: 60px;
  padding: 0px 16px;
  justify-content: space-around;
  border-right: 1px solid ${({theme}) => theme.divider.grey};
  border-bottom: 1px solid ${({theme}) => theme.divider.grey};

  ${BREAKPOINTS.small`
    border-right: none;
  `}
`;

export const StyledNavLink = styled(NavLink)<StyledNavLinkProps>`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid ${({theme, $isActive}) => ($isActive ? theme.input.color : 'transparent')};
  color: ${({theme, $isActive}) => ($isActive ? theme.input.color : theme.text.color.subtitle)};
  font-weight: ${({$isActive}) => ($isActive ? 600 : 400)};

  &:focus,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }

  &:focus-visible {
    outline: 2px solid ${({theme}) => theme.brand};
    outline-offset: 2px;
  }

  @media (hover) {
    &:hover {
      color: ${({theme}) => theme.input.color};
    }
  }
`;

export const ControlsRow = styled(FlexRow)`
  pointer-events: none;
`;

export const MapWrapper = styled(FlexRow)`
  align-items: center;
  height: calc(var(--100vh, 100vh) - 60px);
  justify-content: center;
  background: ${({theme}) => theme.background.sand};
  grid-area: map;

  ${BREAKPOINTS.small`
    height: calc(var(--100vh, 100vh) - 60px - 64px - var(--calendar-sync-tile-height) - var(--tabs-height));
  `}
`;

export const RoomListWrapper = styled.div<{
  $roomListVerticalOpen: boolean;
}>`
  grid-area: room-list;
  overflow: hidden auto;
  background: ${({theme}) => theme.background.white};
  z-index: 2;
  height: calc(var(--100vh, 100vh) - 60px);

  ${BREAKPOINTS.small`
    display: ${({$roomListVerticalOpen}) => (!$roomListVerticalOpen ? 'none' : 'block')};
    height: calc(var(--100vh, 100vh) - 60px - 64px);
  `}
`;

export const VerticalRoomListWrapper = styled.div<{
  $roomListVerticalOpen: boolean;
}>`
  background: ${({theme}) => theme.background.white};
  z-index: 2;

  ${BREAKPOINTS.small`
    display: ${({$roomListVerticalOpen}) => (!$roomListVerticalOpen ? 'none' : 'grid')};
  `}
`;

import {IconButtonProps} from './types';

import {Icon} from '@atoms';
import {StyledIconButton} from './styles';
import {pxToRem} from '@utils';

export const IconButton = ({className, icon, iconButton, size = 'default', style, ...props}: IconButtonProps) => {
  return (
    <StyledIconButton
      $iconButton={iconButton}
      $size={size}
      className={className}
      style={style}
      {...props}>
      <Icon
        aria-label={props['aria-label']}
        icon={icon}
        color="currentColor"
        size={
          size === 'default'
            ? pxToRem(20)
            : size === 'small'
            ? pxToRem(18)
            : size === 'xsmall'
            ? pxToRem(16)
            : pxToRem(24)
        }
        weight="light"
      />
    </StyledIconButton>
  );
};

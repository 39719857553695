import {getFloorById, type Area} from '@lib/store';
import {StyledModalCellItemIcon, StyledTruncatedText, Wrapper} from './styles';
import {FlexCol, FlexRow, P} from '@quarks';
import {useTheme} from 'styled-components';
import {useAppSelector} from '@hooks';

type HaNWorkspaceCellProps = {
  area: Area;
  onClick: (area: Area) => void;
};

export const HaNWorkspaceCell = ({area, onClick}: HaNWorkspaceCellProps) => {
  const theme = useTheme();
  const floor = useAppSelector((state) => getFloorById(state, area.floorId))!;
  return (
    <Wrapper
      tabIndex={1}
      onClick={() => onClick(area)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') onClick(area);
      }}>
      <FlexRow
        gap={8}
        alignItems="center"
        maxWidth="250px"
        sm={{maxWidth: 225}}>
        <StyledModalCellItemIcon
          backgroundColor={theme.card.beige500.background}
          icon={area.hasBookableDesks ? 'desk' : 'area'}
        />
        <FlexCol
          justifyContent="center"
          gap={2}
          overflow="hidden">
          <StyledTruncatedText fontWeight="bold">{area.name}</StyledTruncatedText>
          <P>{floor.name}</P>
        </FlexCol>
      </FlexRow>
    </Wrapper>
  );
};

import {BREAKPOINTS} from '@constants';
import styled from 'styled-components';

export const CalendarDayOverview = styled.div<{$showCalendar: boolean}>`
  background: ${({theme}) => theme.background.sand};
  display: grid;
  grid-area: overview;
  grid-template-columns: [horizontal-calendar-start] 1fr [overview-start] 760px [overview-end] 1fr [horizontal-calendar-end];
  grid-template-rows: [overview-start] 1fr [overview-end];
  height: calc(100vh - 60px);
  justify-content: flex-start;
  overflow: hidden auto;
  padding: 32px 0px;
  background: ${({theme}) => theme.background.sand};

  ${BREAKPOINTS.large`
    grid-template-columns: [overview-start] 1fr [overview-end];
    padding: 0;
    display: flex;
    flex-direction: column;
    background: ${({theme}) => theme.card.white.background};
  `}

  ${BREAKPOINTS.medium`
    grid-template-columns: [horizontal-calendar-start overview-start] 1fr [overview-end horizontal-calendar-end];
    grid-template-rows: [horizontal-calendar-start] auto [horizontal-calendar-end overview-start] 1fr [overview-end];
    grid-area: fullbleed;
    height: unset;
    background: ${({theme}) => theme.background.white};
  `}
  
  ${BREAKPOINTS.small`
    display: ${({$showCalendar}) => (!$showCalendar ? 'grid' : 'none')};
    height: unset;
    overflow: unset;
    background: ${({theme}) => theme.background.sand};
  `};
`;

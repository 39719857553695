import {useTranslation} from 'react-i18next';
import {MeetingRoomInfoProps} from './types';

import {pxToRem} from '@utils';

import {FlexCol, FlexRow, H1, P} from '@quarks';
import {Icon} from '@atoms';

export const MeetingRoomInfo = ({roomName, buildingName, floorName, capacity}: MeetingRoomInfoProps) => {
  const {t} = useTranslation();

  return (
    <FlexCol
      gap={16}
      data-testid="organisms-meetingRoom_MeetingRoomInfo">
      <H1 as="h2">{roomName}</H1>
      <P>{[buildingName, floorName].join(', ')}</P>
      <FlexRow
        justifyContent="flex-start"
        gap={8}>
        <Icon
          icon="chair"
          weight="thin"
          size={pxToRem(24)}
        />
        <P>{t('meeting:MeetingRoomSeat', {count: capacity})}</P>
      </FlexRow>
    </FlexCol>
  );
};

import {pxToRem} from '@utils';
import styled from 'styled-components';
import {StyledProgressIndicatorProps} from './types';

const indicatorWidth = 48;

export const StyledProgressIndicator = styled.div<StyledProgressIndicatorProps>`
  background: ${({theme}) => theme.occupancy.background};
  height: ${pxToRem(3)};
  width: ${pxToRem(indicatorWidth)};

  &::before {
    content: '';
    display: flex;
    justify-content: end;
    width: ${({$width}) => `${$width * 100}%`};
    height: 100%;
    white-space: nowrap;
    background: ${({$disabled, $width, theme}) =>
      $disabled
        ? theme.occupancy.disabled
        : $width > 0.75
        ? theme.occupancy.green
        : $width > 0.5
        ? theme.occupancy.yellow
        : $width > 0.25
        ? theme.occupancy.orange
        : theme.occupancy.red};
    background-size: 100% 100%;
  }
`;

import {Loader} from '@atoms';
import {P} from '@quarks';
import {useEffect, useRef, useState} from 'react';
import {type MapiqMap} from '../../../../../../submodules/map/mapiq-map/MapiqMap';
import {MapRef, MapViewHighlight, MapViewProps} from './types';
import {useTranslation} from 'react-i18next';
import {StyledMapView, StyledMapWrapper} from './styles';
import {highlightsAreEqual} from './mapViewHighlightsAreEqual';
import {getMapDataByFloorId, loadMap, withAsyncThunkErrorHandling} from '@lib/store';
import {useAppDispatch, useAppSelector, useFeatureToggle} from '@hooks';
import {buildingNodeStatesAreEqual} from './mapViewBuildingNodeStatesAreEqual';
import {BuildingNodeState} from '../../../../../../submodules/map/mapiq-map/MapState';
import {useGlobalMapTypePreference} from './useGlobalMapTypePreference';

/*
  This component is quite complex because it bridges the gap between React's state management 
  and the imperative API of the map widget.

  Initially, you should see this cycle happen:

  - Render 1
  - mapData initiates fetch for SVG, returns status Loading
  - syncMap effect triggers -> returns early because mapData is loading
  - updateMarkers effect triggers -> skips because waitingForMap == true
  
  ~ async map loading delay ~

  - Render 2, triggered by mapData resolving to Loaded
  - syncMap effect triggers -> schedules a render on the next animation frame
  - updateMarkers effect triggers -> skips because waitingForMap == true

  ~ animation frame delay ~
  
  - Scheduled createMap runs. Adds the map to the DOM, sets the SVG and changes waitingForMap to false
  - Render 3, triggered by setWaitingForMap
  - syncMap is skipped, no dependency changes
  - updatemarkers effect triggers -> renders markers to the map

  Some things you can change without creating a new map instance
  - When changing onClick, the onClickRef is updated. It will run the next time the user taps the map
  - When changing highlights, only the updateMarkers effect triggers. If the markers are different from
    the previously rendered ones, it resets the markers in the map widget and zooms to fit the whole floor
  - When the floorId changes:
    - mapData goes to Loading
    - syncMap calls dispose, resetting waitingForMap to true and clearing last rendered markers
    - Everything starts over

*/
export const MapView = ({
  buildingId,
  floorId,
  highlights = [],
  buildingNodeStates,
  buildingNodeTypeStates,
  onClick = (_t) => {},
  borderRadius = 4,
  fullView = false,
  disablePointerEvents = false,
  spotlightId = null,
  elementsToKeepInView,
  viewportRestrictions,
}: MapViewProps) => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const allow3dMap = useFeatureToggle().ThreeDMaps;

  //// REFS
  // The MapiqMap injects HTML in to this container. React should not touch it between renders.
  const containerRef = useRef<HTMLDivElement | null>(null);

  // The MapiqMap instance should survive re-renders. Store it in a ref.
  const mapRef = useRef<MapRef | null>(null);

  // The MapiqMap needs a persistant click-handler that has access to this component's state. Another
  // useRef it is:
  const onClickRef = useRef(onClick);
  useEffect(
    function storeLatestClickHandler() {
      onClickRef.current = onClick;
    },
    [onClick],
  );

  let [type, setMapType] = useGlobalMapTypePreference();
  if (!allow3dMap) type = '2d';

  // Markers should only be added to the map when they actually change. We can't rely on Object.is equality
  // because it's not clear to components higher up the chain that they should memo.
  const lastRenderedHighlightsRef = useRef<MapViewHighlight[] | null>(null);
  const lastRenderedBuildingStatesRef = useRef<ReadonlyMap<string, BuildingNodeState> | null>(null);

  const mapData = useAppSelector(getMapDataByFloorId(floorId, type));
  const mapNeedsToBeLoaded = mapData.status === 'NotLoaded';

  useEffect(
    function autoLoad() {
      if (mapNeedsToBeLoaded) {
        dispatch(withAsyncThunkErrorHandling(() => loadMap({buildingId, floorId, type, rendererVersion2: true})));
      }
    },
    [dispatch, buildingId, floorId, type, mapNeedsToBeLoaded],
  );

  // Because our map renderer + component is still not 100% stable, we wrap code in a try-catch
  // to ensure we don't break any components outside of this mapView if things go wrong.
  const [hasError, setHasError] = useState(false);

  const isLoading = mapData.status === 'Loading' || mapData.status === 'NotLoaded';
  const errorMessage =
    mapData.status === 'Failed' ? t('error:FailedToLoadMap') : hasError ? t('error:FailedToDisplayMap') : '';

  // We have to wait a frame before we can render our map; it needs its dimensions. This ensures
  // we do not try to add highlights (markers) to the map in between.
  const [waitingForMap, setWaitingForMap] = useState(true);

  useEffect(() => {
    if (elementsToKeepInView && !isLoading) {
      mapRef.current?.map.fitBuildingNodes({
        nodeIds: elementsToKeepInView,
        abortIfAlreadyInView: true,
        viewportRestriction: viewportRestrictions,
      });
    }
  }, [elementsToKeepInView, isLoading, viewportRestrictions]);

  //// EFFECTS
  useEffect(
    function syncMap() {
      let canceled = false;
      let animationFrame = -1;

      const container = containerRef.current;
      if (!container) return;

      const mapDataUpToDate = mapData.floorId === floorId && mapData.type === type && mapData.status === 'Loaded';
      if (!mapDataUpToDate) return;

      const disposeMap = () => {
        setWaitingForMap(true);

        mapRef.current?.map.dispose();
        container.innerHTML = '';
        mapRef.current = null;

        lastRenderedHighlightsRef.current = null;
        lastRenderedBuildingStatesRef.current = null;
      };

      const createMap = async () => {
        try {
          setWaitingForMap(true);
          let newMap: MapiqMap;

          if (mapData.type === '3d') {
            const {createMapiqMap3d} = await import('../../../../../../submodules/map/mapiq-map/MapiqMap3d');
            newMap = createMapiqMap3d(container, mapData.featureCollection);
          } else if (mapData.type === '2d') {
            const {createMapiqMap} = await import('../../../../../../submodules/map/mapiq-map/MapiqMap');
            newMap = createMapiqMap(container, mapData.svg);
          } else {
            throw new Error('Unsupported map data type');
          }

          if (canceled) {
            newMap.dispose();
            container.innerHTML = '';
            return;
          }

          // Attach a persistant event listener that points to the onClick ref
          newMap.addEventListener('tap', (target) => {
            onClickRef.current(target);
          });

          // Ensure initial fit
          newMap.fitBuildingNodes({nodeIds: [], animated: false});

          // Store a ref to what we just rendered
          mapRef.current = {
            floorId: floorId,
            map: newMap,
          };

          lastRenderedHighlightsRef.current = [];
          lastRenderedBuildingStatesRef.current = new Map<string, BuildingNodeState>();

          setHasError(false);
          setWaitingForMap(false);
        } catch (err) {
          setHasError(true);
          console.error(err);
        }
      };

      // Check if we have to clean up an old floor
      const differentFloorRendered = mapRef.current && mapRef.current.floorId !== floorId;
      if (differentFloorRendered) {
        disposeMap();
      }

      // Check if we have to render a new floor
      const floorAlreadyRendered = mapRef.current?.floorId === floorId;
      if (!floorAlreadyRendered) {
        // Create map on next frame
        animationFrame = requestAnimationFrame(() => {
          createMap();
        });
      }

      return () => {
        canceled = true;
        cancelAnimationFrame(animationFrame);
        disposeMap();
      };
    },
    [floorId, mapData, type],
  );

  // This allows us to programmaticaly apply hover effect
  useEffect(() => {
    mapRef.current?.map.setSpotlight(spotlightId);
  }, [spotlightId]);

  useEffect(
    function updateMarkers() {
      if (waitingForMap) {
        return;
      }

      let animationFrame = -1;

      animationFrame = requestAnimationFrame(() => {
        const highlightsBelongToDifferentFloor = floorId !== mapRef.current?.floorId;
        if (highlightsBelongToDifferentFloor) {
          // When `waitingForMap` is correctly set, this should never be hit. Still, it's
          // safer to leave it in.
          return;
        }

        const currentBuildingNodeStates = lastRenderedBuildingStatesRef.current;
        const buildingNodeStateMapsAreEqual =
          buildingNodeStates &&
          currentBuildingNodeStates &&
          buildingNodeStatesAreEqual(buildingNodeStates, currentBuildingNodeStates);
        const currentlyVisibleHighlights = lastRenderedHighlightsRef.current;
        const highlightsAlreadyDisplayed =
          currentlyVisibleHighlights && highlightsAreEqual(currentlyVisibleHighlights, highlights);
        try {
          // Update markers if needed
          if (!highlightsAlreadyDisplayed) {
            mapRef.current?.map.setMarkerData(highlights);
            lastRenderedHighlightsRef.current = highlights;
          }

          // Update state if needed
          if (!buildingNodeStateMapsAreEqual) {
            mapRef.current?.map.setState({buildingNodeStates, buildingNodeTypeStates});
            lastRenderedBuildingStatesRef.current = buildingNodeStates!;
          }
        } catch (err) {
          console.error(err);
          setHasError(true);
        }
      });

      return () => {
        cancelAnimationFrame(animationFrame);
      };
    },
    /**
     * leaving out `buildingNodeStates` on purpose.
     * It causes the map preview to misallign when selecting a different location with a map and then reselecting the initial location
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [floorId, highlights, waitingForMap],
  );

  return (
    <StyledMapView
      data-testid="molecules-MapView-MapView_map-container"
      justifyContent="center"
      alignItems="center"
      $hasError={errorMessage.length > 0}
      $borderRadius={borderRadius}
      $fullView={fullView}
      $disablePointerEvents={disablePointerEvents}>
      {isLoading ? (
        <Loader />
      ) : errorMessage ? (
        <P>
          <strong>{errorMessage}</strong>
        </P>
      ) : (
        <>
          <StyledMapWrapper ref={containerRef} />

          {allow3dMap && (
            <div className="Toggle2d3d">
              <label>
                <input
                  type="radio"
                  value="3d"
                  checked={type === '3d'}
                  onChange={(e) => {
                    setMapType('3d');
                  }}
                />
                3d
              </label>
              <label>
                <input
                  type="radio"
                  value="2d"
                  checked={type === '2d'}
                  onChange={(e) => {
                    setMapType('2d');
                  }}
                />
                2d
              </label>
            </div>
          )}
        </>
      )}
    </StyledMapView>
  );
};

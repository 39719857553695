import {useTranslation} from 'react-i18next';
import {ProfileCell} from '../styles';
import {P} from '@quarks';
import {IconButton} from '@molecules';
import {useAppDispatch, useAppSelector, useDialog, useFeatureToggle} from '@hooks';
import {getIsParkingFeatureVisible, getLicensePlate, updateLicensePlate, withAsyncThunkErrorHandling} from '@lib/store';
import {StyledColumn, StyledText} from './styles';

export const LicensePlateCell = () => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const {LicensePlate} = useFeatureToggle();
  const isParkingFeatureVisible = useAppSelector((state) => getIsParkingFeatureVisible(state));
  const {destructiveDialog} = useDialog();

  const licensePlate = useAppSelector(getLicensePlate);

  const isDelete = () => !!licensePlate;

  const buttonIcon = isDelete() ? 'trash' : 'plus';
  const buttonAriaLabel = isDelete()
    ? t('profile:DeleteLicensePlateButtonLabel')
    : t('profile:AddLicensePlateButtonLabel');

  const addLicensePlate = async () => {
    const newPlate = window.prompt('Add your license plate');
    if (typeof newPlate === 'string') {
      await dispatch(withAsyncThunkErrorHandling(() => updateLicensePlate(newPlate)));
    }
  };

  const deleteLicensePlate = () => dispatch(withAsyncThunkErrorHandling(() => updateLicensePlate('')));

  const showDeleteLicensePlateDialog = async () => {
    const confirmation = await destructiveDialog(
      t('profile:DeleteLicensePlateTitle'),
      t('profile:DeleteLicensePlateDescription'),
      'reportProblem',
    );

    if (confirmation) {
      await deleteLicensePlate();
    }
  };

  const handleClick = async () => {
    if (isDelete()) {
      await showDeleteLicensePlateDialog();
    } else {
      await addLicensePlate();
    }
  };

  if (!(LicensePlate && isParkingFeatureVisible)) {
    return <></>;
  }

  return (
    <ProfileCell>
      <StyledColumn
        alignItems="center"
        left={<P>{t('profile:LicensePlate')}</P>}
        middle={<StyledText>{licensePlate}</StyledText>}
        right={
          <IconButton
            icon={buttonIcon}
            iconButton="tertiary"
            size="small"
            aria-label={buttonAriaLabel}
            onClick={handleClick}
          />
        }
      />
    </ProfileCell>
  );
};

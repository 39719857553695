import {MapView, NoMapPlaceholder} from '@molecules';
import {HereAndNowWorkspacesMapProps} from './types';
import {useAppSelector} from '@hooks';
import {getFloorHasMap} from '@lib/store';
import {useMemo} from 'react';
import {createState, mergeState} from '@utils';
import {AreaMarkerData} from '../../../../../../../submodules/map/mapiq-map/markers/NodeMarkerData';
import {AreaEventTarget} from '../../../../../../../submodules/map/mapiq-map/EventTarget';

export const HaNWorkspacesMap = ({
  floorId,
  floorsWithMap,
  viewportRestrictions,
  buildingId,
  onAreaClick,
  selectedArea,
  hoverArea,
  workspaces,
}: HereAndNowWorkspacesMapProps) => {
  const floorHasMap = useAppSelector(getFloorHasMap(floorId));

  const hightlights: AreaMarkerData[] = workspaces
    .filter((area) => area.buildingId === buildingId && area.floorId === floorId)
    .map((area) => ({
      type: 'area',
      nodeId: area.id,
      available: area.capacity,
      connections: [],
    }));

  const showMap = floorHasMap && floorsWithMap.length !== 0 && floorId && workspaces.length !== 0;

  const highlightedAreas = useMemo(
    () => workspaces.filter((area) => area.id !== selectedArea?.id),
    [workspaces, selectedArea],
  );

  const availableAreaState = createState(
    highlightedAreas.filter((area) => area.id !== selectedArea?.id).map((area) => area.id),
    'highlighted',
  );

  const selectedAreaState = useMemo(
    () => createState(selectedArea ? [selectedArea.id] : [], 'selected'),
    [selectedArea],
  );

  const allAreas = [availableAreaState, selectedAreaState].reduce(mergeState);

  const handleClick = (e: AreaEventTarget) => {
    if (e.type === 'area') {
      onAreaClick(e);
    }
  };

  const elementsToKeepInView = useMemo(() => (selectedArea ? [selectedArea.id] : undefined), [selectedArea]);

  return showMap ? (
    <MapView
      borderRadius={0}
      floorId={floorId}
      buildingId={buildingId}
      highlights={hightlights}
      fullView={true}
      elementsToKeepInView={elementsToKeepInView}
      disablePointerEvents={false}
      buildingNodeStates={allAreas}
      viewportRestrictions={viewportRestrictions}
      onClick={(e) => handleClick(e as AreaEventTarget)}
      spotlightId={hoverArea?.floorId === floorId ? hoverArea.id : null}
    />
  ) : (
    <NoMapPlaceholder
      floorId={floorId}
      floorsWithMap={floorsWithMap}
      arrayLength={workspaces.length}
    />
  );
};

import {pxToRem} from '@utils';
import styled from 'styled-components';
import {RangeStyleProps, WrapperProps} from './types';
import CheckSvg from '@svgs/check.svg';

export const Wrapper = styled.label<WrapperProps>`
  align-items: center;
  background: ${({theme}) => theme.input.background};
  border-radius: 8px;
  border: 1px solid ${({theme}) => theme.input.border.default};
  color: ${({$value, theme}) => (!!$value ? theme.input.color : theme.input.placeholder)};
  cursor: text;
  display: flex;
  gap: 0 8px;
  font-size: ${pxToRem(15)};
  line-height: 1;
  overflow: hidden;
  padding: ${({$type}) => ($type === 'search' ? '5px 16px 5px 8px' : '8px 16px')};
  position: relative;
  width: ${({$size, $type}) =>
    $size === 'full'
      ? '100%'
      : $type === 'number'
      ? 'clamp(64px, 4rem, calc(100vw - 16px))'
      : 'clamp(100px, calc(311 / 16 * 1rem), calc(100vw - 32px))'};

  @media (hover) {
    &:hover {
      border: 1px solid ${({theme}) => theme.input.hover.border};
    }
  }

  &:focus-within {
    border: 1px solid ${({theme}) => theme.input.hover.border};
  }
`;

export const StyledInput = styled.input`
  &[type='text'],
  &[type='search'],
  &[type='number'] {
    background: transparent;
    border: none;
    cursor: inherit;
    flex: 1;
    font: inherit;
    height: ${pxToRem(22)};
    width: 100%;

    &::placeholder {
      color: ${({theme}) => theme.input.placeholder};
      opacity: 1;
    }

    &:focus-visible {
      outline: none;
    }
  }

  &[type='search'] {
    // hide search icon on ios safari
    appearance: none;
  }

  &[type='number'] {
    appearance: textfield;
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }

  &[type='radio'] {
    appearance: none;
    background: none;
    border: 1px solid currentColor;
    border-radius: 9999px;
    color: ${({theme}) => theme.input.border.checked};
    cursor: pointer;
    height: ${pxToRem(20)};
    position: relative;
    width: ${pxToRem(20)};

    &:checked::before {
      background: currentColor;
      border-radius: 9999px;
      content: '';
      height: ${pxToRem(12)};
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
      width: ${pxToRem(12)};
    }

    &:checked:not(:disabled) {
      color: ${({theme}) => theme.input.border.checked};
    }

    &:disabled {
      color: ${({theme}) => theme.input.border.default};
      background-color: ${({theme}) => theme.input.background};
    }

    &:focus-visible {
      outline-offset: 2px;
      outline: 2px solid ${({theme}) => theme.link.hover};
    }
  }

  &[type='checkbox'] {
    appearance: none;
    background: none;
    border: 1px solid currentColor;
    border-radius: 4px;
    color: ${({theme}) => theme.input.border.checked};
    cursor: pointer;
    height: ${pxToRem(20)};
    position: relative;
    width: ${pxToRem(20)};

    &:checked::before {
      content: '';
      background: currentColor;
      position: absolute;
      inset: 0;
      background-image: url(${CheckSvg});
      background-size: contain;
      background-repeat: no-repeat;
    }

    &:checked:not(:disabled) {
      color: ${({theme}) => theme.input.border.checked};
    }

    &:disabled {
      color: ${({theme}) => theme.input.border.default};
      background-color: ${({theme}) => theme.input.background};
    }

    &:focus-visible {
      outline-offset: 2px;
      outline: 2px solid ${({theme}) => theme.link.hover};
    }
  }
`;

export const StyledRangeInput = styled.input<RangeStyleProps>`
  &[type='range'] {
    -webkit-appearance: none;
    appearance: none;
    min-width: 200px;
    background: transparent;

    &::-webkit-slider-runnable-track,
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
    }

    &::-webkit-slider-runnable-track {
      height: 10px;
      background: linear-gradient(to right, black, black), ${({theme}) => theme.input.background};
      border: 1px solid black;
      background-size: ${(props) => `${props.$trackWidth}% 100%`};
      background-repeat: no-repeat;
      border-radius: 15px;
    }

    &::-webkit-slider-thumb {
      width: 24px;
      height: 24px;
      cursor: pointer;
      background: white;
      border: 1px solid ${({theme}) => theme.input.placeholder};
      border-radius: 50%;
      margin-top: -7px;
    }

    &::-moz-range-progress {
      background-color: ${({theme}) => theme.text.color.body};
      border-radius: 5px 0 0 5px;
      height: 10px;
    }

    &::-moz-range-track {
      background-color: ${({theme}) => theme.input.background};
      border: 1px solid black;
      border-radius: 5px;
      height: 10px;
    }

    &::-moz-range-thumb {
      width: 24px;
      height: 24px;
      cursor: pointer;
      background: white;
      border: 1px solid ${({theme}) => theme.input.placeholder};
      border-radius: 50%;
      margin-top: -50px;
    }
  }
`;

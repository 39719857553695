import {useAppSelector} from '@hooks';
import {Area, getBuildingById, getFloorById, getFloorHasMap, getConnectedUsersByDate} from '@lib/store';
import {useTranslation} from 'react-i18next';
import {StyledParagraph, StyledUserCell, WorkspaceInformationWrapper} from './styles';
import {HaNModalHeader} from '@organisms';
import {StyledBody} from '../HaNRoomInformation/styles';
import {MapView} from '@molecules';
import {createState, getDeskAmenityIcon, pxToRem} from '@utils';
import {useMemo} from 'react';
import {Div, FlexCol, FlexRow, H1, H2, P} from '@quarks';
import {Icon} from '@atoms';

type HaNWorkspaceInformationProps = {
  onClose: () => void;
  workspace: Area;
};

export const HaNWorkspaceInformation = ({workspace, onClose}: HaNWorkspaceInformationProps) => {
  const {t} = useTranslation();
  const date = new Date();
  const floorHasMap = useAppSelector(getFloorHasMap(workspace.floorId));
  const {name, buildingId, floorId, capacity, activityTypes, deskAmenities, id: areaId} = workspace;
  const selectedAreaState = useMemo(() => createState(workspace ? [workspace.id] : [], 'selected'), [workspace]);

  const building = useAppSelector((state) => getBuildingById(state, buildingId));
  const floor = useAppSelector((state) => getFloorById(state, floorId));
  const connections = useAppSelector((state) =>
    getConnectedUsersByDate(state, {date: date.toISOString(), nodeId: areaId}),
  );

  return (
    <>
      <WorkspaceInformationWrapper data-testid="HaNWorkspaceInformation-MainWrapper">
        <HaNModalHeader
          onClose={onClose}
          title={t('workplace:Area')}
        />
        <StyledBody as="section">
          {floorHasMap ? (
            <Div
              display="none"
              sm={{display: 'block'}}>
              <MapView
                buildingId={buildingId}
                floorId={floorId}
                buildingNodeStates={selectedAreaState}
                borderRadius={8}
                fullView={false}
                highlights={[
                  {
                    type: 'area',
                    nodeId: workspace.id,
                    available: workspace.capacity,
                    connections: [],
                  },
                ]}
                disablePointerEvents={true}
                data-testid="HaNWorkspaceInformation-Map"
              />
            </Div>
          ) : null}
          {building?.name || floor?.name ? (
            <FlexCol
              gap={16}
              data-testid="HaNWorkspaceInfromation-BuildingAndFloorNameWrapper">
              <H1 as="h2">{name}</H1>
              <P>{[t('meeting:MeetingRoomSeat', {count: capacity}), building?.name, floor?.name].join(', ')}</P>
            </FlexCol>
          ) : null}
          {activityTypes.length ? (
            <FlexCol
              gap={16}
              data-testid="HaNWorkspaceInformation-ActivityTypesWrapper">
              <H2 as="h3">{t('workplace:ActivityTypesFilterSectionTitle')}</H2>
              <StyledParagraph>
                {activityTypes.map((activity) => t(`activityTypes:activityType`, {context: activity})).join(', ')}
              </StyledParagraph>
            </FlexCol>
          ) : null}
          {deskAmenities.length ? (
            <FlexCol
              gap={16}
              data-testid="HaNWorkspaceInformation-DeskAmenitiesWrapper">
              <H2 as="h3">{t('deskAmenities:deskAmenity_plural')}</H2>
              <P>{t('workplace:DeskAmenities')}</P>
              {deskAmenities.map((amenity) => {
                return (
                  <FlexRow
                    gap={8}
                    key={amenity}
                    alignItems="center"
                    justifyContent="flex-start">
                    <Icon
                      weight="thin"
                      size={pxToRem(24)}
                      icon={getDeskAmenityIcon(amenity)}></Icon>
                    <P>{t(`deskAmenities:deskAmenity`, {context: amenity})}</P>
                  </FlexRow>
                );
              })}
            </FlexCol>
          ) : null}
          {connections.length ? (
            <FlexCol gap={16}>
              <H2 as="h3">{t('connection:ConnectionsWorkingHere')}</H2>
              {connections.map((connection) => (
                <StyledUserCell
                  user={connection}
                  key={JSON.stringify(connection)}
                />
              ))}
            </FlexCol>
          ) : null}
        </StyledBody>
      </WorkspaceInformationWrapper>
    </>
  );
};

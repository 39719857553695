import {
  compareByMeetingResponse,
  compareByTemplateWithStringFallback,
  MeetingAttendee,
  MeetingAttendeeType,
  MeetingRoom,
} from '@lib/store';
import _, {groupBy} from 'lodash';

export const sortAttendeesByLocation = (
  attendees: MeetingAttendee[],
  currentUser: MeetingAttendee | undefined,
  defaultBuildingId: string,
  rooms: Record<string, MeetingRoom[]>,
) => {
  const attendeesGroupedByLocation = groupBy(attendees, (attendee) => attendee.locationType);

  const localAttendeesGroupedByLocation = _(attendeesGroupedByLocation.Local)
    .groupBy((meetingAttendee) => meetingAttendee.shiftBuildingId)
    .map((meetingAttendees, key) => ({
      attendees: meetingAttendees.sort(compareByMeetingResponse),
      buildingId: key,
      id: key,
      locationDescription: meetingAttendees[0].shiftBuildingName,
      locationType: MeetingAttendeeType.Local,
    }))
    .value();

  const generalAttendeesGroupedByLocation = _(attendeesGroupedByLocation)
    .pickBy((_item, key) => key !== 'Local')
    .map((meetingAttendees, key) => ({
      attendees: meetingAttendees.sort(compareByMeetingResponse),
      buildingId: undefined,
      id: key,
      locationDescription: key,
      locationType: key as MeetingAttendeeType,
    }))
    .value();

  const buildingIdsForMeeting = Object.keys(rooms ?? {});

  const buildingsIdsWithoutAttendees = buildingIdsForMeeting?.filter(
    (buildingId) => !localAttendeesGroupedByLocation?.find((group) => group.buildingId === buildingId),
  );

  const roomOnlyGroups = buildingsIdsWithoutAttendees.map((buildingId) => ({
    attendees: [],
    id: buildingId,
    buildingId,
    locationDescription: rooms[buildingId]?.[0]?.buildingName,
    locationType: MeetingAttendeeType.Local,
  }));

  const locationArray = [...localAttendeesGroupedByLocation, ...generalAttendeesGroupedByLocation, ...roomOnlyGroups];

  const defaultBuildingGroup = locationArray.find((location) => location.buildingId === defaultBuildingId);
  const myBuildingGroup = locationArray.find((location) => location.buildingId === currentUser?.shiftBuildingId);

  const sortingOrder =
    currentUser?.locationType === 'Local'
      ? [myBuildingGroup?.buildingId, defaultBuildingGroup?.buildingId, 'OtherBuildings', 'Remote', 'Unknown']
      : currentUser?.locationType === 'Remote'
      ? ['Remote', defaultBuildingGroup?.buildingId, 'OtherBuildings', 'Unknown']
      : [defaultBuildingGroup?.buildingId, 'OtherBuildings', 'Remote', 'Unknown'];

  const otherBuildingsIndex = sortingOrder.indexOf('OtherBuildings');

  const returnedArray = _(locationArray)
    .sort((a, b) => compareByTemplateWithStringFallback(sortingOrder, otherBuildingsIndex, a, b))
    .value();

  return returnedArray;
};

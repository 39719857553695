import {StrictMode} from 'react';
import {createRoot} from 'react-dom/client';
import {BrowserRouter as Router} from 'react-router-dom';
import {Contexts} from '@contexts';
import {MsalProvider} from '@azure/msal-react';
import {App} from './App';
import i18n, {initI18Next} from './i18n';
import {
  apiClient,
  authManager,
  calendarService,
  eventTracker,
  notificationService,
  secureStorage,
  webLocalStorage,
  createMsalInstance,
  translationService,
} from '@infrastructure';
import {createStore} from '@lib/store';
import {Provider} from 'react-redux';
import {AppInsights} from '@config';

AppInsights.loadAppInsights();
AppInsights.context.application.ver = import.meta.env.MAPIQ_APP_VERSION;
console.log('version', import.meta.env.MAPIQ_APP_VERSION);

const i18nSvc = translationService(i18n);
const msalInstance = createMsalInstance();
const localStore = webLocalStorage();
const secureStore = secureStorage();
const manager = authManager(msalInstance);
const tracker = eventTracker();
const client = apiClient(manager, tracker, i18nSvc);
const notifService = notificationService();
const calService = calendarService();
const store = createStore({
  authManager: manager,
  apiClient: client,
  localStore: localStore,
  secureStore: secureStore,
  i18n: i18nSvc,
  applicationInsights: {eventTracker: tracker, appType: 'UserApp'},
  notifications: notifService,
  calendarService: calService,
});

import '@styles/fonts.css';

initI18Next(store.dispatch);

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
root.render(
  <StrictMode>
    <Provider store={store}>
      <MsalProvider instance={msalInstance}>
        <Router>
          <Contexts>
            <App />
          </Contexts>
        </Router>
      </MsalProvider>
    </Provider>
  </StrictMode>,
);

import {useEffect} from 'react';
import {CalendarParkingTileProps} from './types';

import {
  getIsUserAllowedToReserveParking,
  getParkingReservationByDate,
  getWorkdayByDate,
  loadParkingCount,
  loadParkingLotsByBuilding,
  withAsyncThunkErrorHandling,
} from '@lib/store';
import {useAppDispatch, useAppSelector, useFeatureToggle, useModal} from '@hooks';
import {parseISO} from 'date-fns';

import {SquareButton} from '@molecules';
import {CalendarParkingTileBookingInfo, ParkingSelectionCard} from '@organisms';

export const CalendarParkingTile = ({date}: CalendarParkingTileProps) => {
  const dispatch = useAppDispatch();
  const {Parking: isFeatureEnabled} = useFeatureToggle();
  const {addPages, openModal} = useModal();

  const parkingReservation = useAppSelector((state) => getParkingReservationByDate(state, date));

  const {nodeId: buildingId} = useAppSelector((state) => getWorkdayByDate(state, date)) || {};
  const isUserAllowedToReserveParking = useAppSelector((state) =>
    getIsUserAllowedToReserveParking(state, parseISO(date)),
  );

  useEffect(() => {
    if (buildingId) {
      dispatch(
        withAsyncThunkErrorHandling(() =>
          loadParkingLotsByBuilding({
            buildingId,
          }),
        ),
      );
      dispatch(withAsyncThunkErrorHandling(() => loadParkingCount({selectedDate: date})));
    }
  }, [dispatch, buildingId, date]);

  if (!isFeatureEnabled) return <></>;

  const isDisabled = !isUserAllowedToReserveParking && !parkingReservation;

  const handleOnClick = () => {
    if (isDisabled) return;

    addPages([<ParkingSelectionCard date={date} />]);
    openModal();
  };

  return (
    <SquareButton
      iconLeft="car"
      iconRight="caretRight"
      squareButton="energizingYellow"
      onClick={handleOnClick}
      disabled={isDisabled}>
      <CalendarParkingTileBookingInfo date={date} />
    </SquareButton>
  );
};

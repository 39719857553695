import {ThreeColumnCell} from '@atoms';
import {P} from '@quarks';
import {truncateString} from '@utils';
import styled from 'styled-components';

export const StyledText = styled(P)`
  ${truncateString};
  flex: 1;
  width: 100%;
  color: ${({theme}) => theme.text.color.subtitle};
  text-align: end;
`;

export const StyledColumn = styled(ThreeColumnCell)`
  flex: 1;
`;

import {pxToRem} from '@utils';
import styled from 'styled-components';
import {StyledIconButtonProps} from './types';

export const StyledIconButton = styled.button<StyledIconButtonProps>`
  align-items: center;
  background: ${({$iconButton, theme}) => theme.button[$iconButton].background};
  border-radius: 6px;
  border: 1.5px solid ${({$iconButton, theme}) => theme.button[$iconButton].border};
  box-shadow: ${({theme}) => theme.boxShadow.secondary};
  color: ${({$iconButton, theme}) => theme.button[$iconButton].color};
  cursor: pointer;
  display: flex;
  padding: 0;
  height: ${({$size}) =>
    $size === 'default'
      ? pxToRem(40)
      : $size === 'small'
      ? pxToRem(32)
      : $size === 'xsmall'
      ? pxToRem(24)
      : pxToRem(56)};
  justify-content: center;
  line-height: 1;
  transition: color, var(--speed-fast), background var(--speed-fast), border var(--speed-fast),
    box-shadow var(--speed-fast);
  width: ${({$size}) =>
    $size === 'default'
      ? pxToRem(40)
      : $size === 'small'
      ? pxToRem(32)
      : $size === 'xsmall'
      ? pxToRem(24)
      : pxToRem(56)};

  &:disabled {
    background: ${({$iconButton, theme}) => theme.button[$iconButton].disabled.background};
    border: 1.5px solid ${({$iconButton, theme}) => theme.button[$iconButton].disabled.border};
    color: ${({$iconButton, theme}) => theme.button[$iconButton].disabled.color};

    @media (hover) {
      &:hover {
        cursor: not-allowed;
      }
    }
  }

  @media (hover) {
    &:hover:not(:disabled) {
      background: ${({$iconButton, theme}) => theme.button[$iconButton].hover.background};
      border: 1.5px solid ${({$iconButton, theme}) => theme.button[$iconButton].hover.border};
      color: ${({$iconButton, theme}) => theme.button[$iconButton].hover.color};
    }
  }

  &:focus-visible {
    outline: 2px solid ${({theme}) => theme.brand};
    outline-offset: 2px;
  }
`;

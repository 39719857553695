import {UserCell} from '@molecules';
import {Div, P} from '@quarks';
import styled from 'styled-components';

export const WorkspaceInformationWrapper = styled(Div)`
  background: ${({theme}) => theme.background.white};
  display: flex;
  height: var(--modal-height);
  flex-direction: column;
`;

export const StyledParagraph = styled(P)`
  text-transform: lowercase;
  ::first-letter {
    text-transform: uppercase;
  }
`;

export const StyledUserCell = styled(UserCell)`
  padding: 0;
  :not(:last-child) {
    border-bottom: none;
  }
`;

import {useModal, useRoomImage} from '@hooks';
import {IconButton, ModalCellItem, ModalCellItemIcon} from '@molecules';
import {CreateEventRoomCellItemProps} from './types';
import {useTranslation} from 'react-i18next';
import {MeetingRoomDetailsCard} from '@organisms';
import {FlexCol, P} from '@quarks';
import {pickUsingStringAsIndex} from '@lib/utils';
import {useTheme} from 'styled-components';

export const CreateEventRoomCellItem = ({room, onClick, onClose, disabled}: CreateEventRoomCellItemProps) => {
  const {t} = useTranslation();
  const {addPages} = useModal();
  const {displayName, capacity, floorName, roomPictureHash, buildingId, email} = room;

  const theme = useTheme();
  const color = pickUsingStringAsIndex(email, theme.avatar.colors);
  const imageURL = useRoomImage(email || displayName, roomPictureHash, buildingId, 48);

  const background = imageURL ? `scroll center/cover no-repeat url('${imageURL}')` : color;

  return (
    <ModalCellItem
      ariaLabel={t('workplace:RoomItemAriaLabel', {
        name: displayName,
        capacity: capacity,
        floor: floorName,
      })}
      alignItems="flex-start"
      left={
        <ModalCellItemIcon
          backgroundColor={background}
          imageURL={imageURL}
          disabled={disabled}
          icon="room"
        />
      }
      disabled={disabled}
      onClick={onClick}
      middle={
        <FlexCol
          height="100%"
          paddingTop={8}>
          <P
            data-testid={`oragnisms-createEvent-CreateEventRoomCellItem_roomTitle-${displayName}`}
            fontWeight="bold"
            color="currentColor">
            {displayName}
          </P>
          <P>
            {t('meeting:MeetingRoomBasicInfo', {
              count: capacity,
              floorName: floorName,
            })}
          </P>
        </FlexCol>
      }
      right={
        <IconButton
          data-testid={`oragnisms-createEvent-CreateEventRoomCellItem_room-info-${displayName}`}
          aria-label={t('workplace:RoomItemDetailsAriaLabel', {name: room.displayName})}
          icon="info"
          size="xsmall"
          iconButton="secondary"
          onKeyDown={(e) => e.stopPropagation()}
          onClick={(e) => {
            e.stopPropagation();
            addPages([
              <MeetingRoomDetailsCard
                room={room}
                onClose={onClose}
              />,
            ]);
          }}
        />
      }
    />
  );
};
